import React, { useEffect, useContext } from "react";
import { Button, MainTitle, Section } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { Title, Box, Text } from "../components/Core";
import { Col, Container, Row } from "react-bootstrap";
import CheckAvailability from "../sections/index/CheckAvailability";
import Seo from "../components/Seo/Seo";
import scrollTo from 'gatsby-plugin-smoothscroll';
import sbvsLogo from "./../assets/image/jpeg/sbvs-logo.jpg"
import GlobalContext from './../context/GlobalContext';
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import ReactMarkdown from 'react-markdown'
import scotland1 from "./../assets/image/rebrand/scotland-1.jpg"
import scotland2 from "./../assets/image/rebrand/scotland-2.jpg"
import circleGradient from "./../assets/image/rebrand/circle-gradient.svg"
const gfm = require('remark-gfm')

const NormalTitle = styled(Title)`
  font-size: 2.3em;
`

const HeroSection = styled(Section)`
    padding-top: 100px;
    padding-bottom: 0;
    position: relative;
    @media (max-width: 1200px) {
        img {
            height: 760px;
            width: auto !important
        }
    }
`

const TransparentBox = styled(Box)`
    background: rgba(255, 255, 255, 0.9);
    padding: 50px;
    border-radius: 25px;
    margin: auto;
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    max-width: 750px;
    text-align: center;
    @media (max-width: 991px) {
        max-width: 991px;
        padding: 50px 10px;
        margin: 10px;
    }
`

const RadiusImage = styled.img`
    border-radius: 20px;
    margin-top: 20px;
    width: 100%;
`

const PinkContainer = styled(Container)`
    background-color: #fdf5f9;
    padding: 50px;
    border-radius: 20px;
    margin-top: 100px;
    @media (max-width: 991px) {
        padding: 15px;
    }
`

const TwoColumn = styled(Col)`
    column-count: 2;
    column-gap: 50px;
    @media (max-width: 991px) {
        column-count: 1;
    }
`

const GradientSection = styled(Section)`
    position: relative;
    background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
`;

const WhiteBox = styled.div`
    padding: 50px;
    border-radius: 30px;
    background-color: #fff;
    max-width: 500px;
    margin: auto;
`

const CircleSection = styled(Section)`
    background: url(${circleGradient}) no-repeat;
    background-size: 585px;
    background-position: 106% 40%;
`

const R100 = () => {
    const gContext = useContext(GlobalContext);
    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    const data = useStaticQuery(graphql`
        query strapiRscotlandQuery {
            strapiRscotland {
                title
                subtitle_text
                subtitle_1
                subtitle_1_text
                subtitle_2
                subtitle_2_text
                subtitle_3
                subtitle_3_text
            }
            strapiPageHeadings(slug: {eq: "scottish-voucher-scheme"}) {
                h1
                h21
                h22
                h23
            }
        }`
    );

    const pageData = getProperty(data, 'strapiRscotland');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <div>
            <Seo page="scotland-r-100" />
            <PageWrapper footerDark>
                <HeroSection>
                    <img src={scotland2} className="w-100" />
                    <TransparentBox>
                        <MainTitle>
                            {headingsData.h1}
                        </MainTitle>
                        <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_text}/>
                        <Button
                            style={{
                                background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                border: 'none',
                                width: 'auto'
                            }}
                            className="btn-red hvr-bounce-to-left mt-5"
                            onClick={() => scrollTo('#check-availability')}
                        >
                            Register now
                        </Button>
                    </TransparentBox>
                </HeroSection>
                <Section bg="#fdf5f9">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <MainTitle className="pink-color">
                                    {headingsData.h21}
                                </MainTitle>
                            </Col>
                            <Col lg="6">
                                <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_1_text}/>
                            </Col>  
                        </Row>
                    </Container>
                </Section>
                <CircleSection>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <NormalTitle>
                                    {headingsData.h22}
                                </NormalTitle>
                                <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_2_text}/>
                            </Col>
                            <Col lg="6" className="align-self-center">
                                <RadiusImage src={scotland1} />
                            </Col>  
                        </Row>
                    </Container>
                    <PinkContainer>
                        <Row>
                            <Col lg="12 text-center">
                                <NormalTitle className="pink-color mb-3">
                                    {headingsData.h23}
                                </NormalTitle>
                            </Col>
                            <TwoColumn lg="12">
                                <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_3_text}/>
                                <img src={sbvsLogo} alt="GBVS" width="250px" className="mt-5"/>
                            </TwoColumn>
                        </Row>
                    </PinkContainer>
                </CircleSection>

                <GradientSection id="check-availability">
                    <Container>
                        <Row>
                            <Col lg="8" className="text-center centered">
                                <Title className="white-color">
                                    Could you benefit from our fibre network?
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <WhiteBox className="mt-5">
                                    <Row>
                                        <Col lg="9" className="text-center centered">
                                            <Text color="black" lineHeight={1.2}>
                                                Enter your postcode to get<br />faster fibre today!
                                            </Text>
                                        </Col>
                                    </Row>
                                    <CheckAvailability
                                        focusInput={false}
                                        hideLogo={true}
                                        customTitle={""}
                                        customSubtitle={""}
                                    />
                                </WhiteBox>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
            </PageWrapper>
        </div>
    )
}

export default R100
